<nav
  class="navbar bg-white navbar-expand-lg fixed-top"
  id="navbar-top"
>
  <div class="container-fluid">
    <div class="navbar-translate">
      <a
        class="navbar-brand"
        [routerLink]="['/']"
      >
      <img src="assets/img/logo-map.png" width="30" class="mr-1">
        <span> SKS <small>special kalasin student</small></span>
      </a>
      <button
        aria-controls="navigation-index"
        aria-label="Toggle navigation"
        class="navbar-toggler navbar-toggler"
        [attr.aria-expanded]="!isCollapsed"
        (click)="isCollapsed = !isCollapsed"
        id="navigation"
        type="button"
        *ngIf="userIsAuthenticated"
      >
        <span class="navbar-toggler-bar bar1"> </span>
        <span class="navbar-toggler-bar bar2"> </span>
        <span class="navbar-toggler-bar bar3"> </span>
      </button>
    </div>
    <div
      class="navbar-collapse justify-content-end"
      [collapse]="isCollapsed"
      id="navigation"
    >
      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand"><a> SKS • </a></div>
          <div class="col-6 collapse-close text-right">
            <button
              aria-controls="navigation-index"
              aria-label="Toggle navigation"
              class="navbar-toggler"
              [attr.aria-expanded]="!isCollapsed"
              (click)="isCollapsed = !isCollapsed"
              id="navigation"
              type="button"
            >
              <i class="tim-icons icon-simple-remove"> </i>
            </button>
          </div>
        </div>
      </div>
      <ul class="navbar-nav" *ngIf="userIsAuthenticated">
        <li class="nav-item p-0">
          <a
            class="nav-link"
            href="javascript:void(0)"
            placement="bottom"
            tooltip="ข้อมูลของฉัน"
          >
            <i class="far fa-user-circle"></i>
            <p class="d-lg-none d-xl-none">ข้อมูลของฉัน</p>
          </a>
        </li>
        <li class="nav-item p-0">
          <a
            class="nav-link"
            href="javascript:void(0)"
            placement="bottom"
            tooltip="ออกจากระบบ"
          >
            <i class="fas fa-power-off text-danger"></i>
            <p class="d-lg-none d-xl-none">ออกจากระบบ</p>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

