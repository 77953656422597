import { Component, OnInit, Input } from '@angular/core';
import { SharedAnimations } from 'src/assets/animations/shared-animations';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  animations: [SharedAnimations]
})
export class LoadingComponent implements OnInit {
  @Input('loading') loading: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
