<div class="card card-detail" [@animate]="{value:'*',params:{opacity:'0', duration: '600ms'}}">
  <div class="card-body">
    <div class="card-body--header">
      <div class="row">
        <div class="col-2 text-center"><i class="tim-icons icon-square-pin"></i></div>
        <div class="col-10">
          <h4 class="mb-1"><span class="font-weight-bold"> จังหวัด </span> {{province}}</h4>
          <p *ngIf="amphoe == null">อำเภอ -</p>
          <p *ngIf="amphoe != null">อำเภอ {{amphoe}}</p>
        </div>
      </div>
    </div>
    <div class="card-body--description">
      <div class="row">
        <div class="col">
          <p>จำนวนนักเรียนที่พบ</p>
        </div>
        <div class="col text-right">
          <p><span class="font-weight-bold"> {{studentCount}} </span> คน</p>
        </div>
      </div>
    </div>
  </div>
</div>
