import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { debounceTime, map } from 'rxjs/operators';

import { StudentService } from './../../services/student.service';
import { Observable, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AddressService } from './../../services/address.service';
import { SearchService } from './../../services/search.service';
import { SharedAnimations } from 'src/assets/animations/shared-animations';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-search-map',
  templateUrl: './search-map.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./search-map.component.scss'],
  animations: [SharedAnimations]
})
export class SearchMapComponent implements OnInit {
  focus;
  public statesWithFlags: any;
  public model: any;
  public students: any;
  public provinces: any;
  public amphoes: any;

  private studentsSub: Subscription;
  clickedItem: [];
  public filter: FilterStudent;
  private idCard: string;
  private province: string;
  private amphoe: string;

  provinceLoading: boolean;
  amphoeLoading: boolean;
  public selectedProvince: string;
  public selectedAmphoe: string;


  @Input('loading') loading: boolean;
  public defaultProvince: string;
  public defaultAmphoe: string;

  constructor(
    private studentService: StudentService,
    private addressService: AddressService,
    private searchService: SearchService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.amphoeLoading = false;
    this.filter = {
      idCard: null,
      province: null,
      amphoe: null,
    }

    this.getProvinces();
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['student'] != '') {

      }

      if (params['provices'] != '') {
        this.selectedProvince = '1';
        if (params['provices'] != null) {
          this.province = params['provices'];
          this.selectedProvince = params['provices'];
          console.log('provices has null', params['provices']);
          this.getAmphoes(params['provices']);
          this.filter = {
            idCard: null,
            province: this.province,
            amphoe: null
          }
        }
      }

      if (params['provices'] != '') {
        this.selectedAmphoe = '1';
        if (params['amphoes'] != null) {
          this.amphoe = params['amphoes'];
          this.selectedAmphoe = params['amphoes'];
          this.filter = {
            idCard: null,
            province: this.province,
            amphoe: this.amphoe
          }
        }
      }

      this.getStudents(this.filter);
    });


  }

  selectDefaultProvince(province: string) {
    if (province === this.defaultProvince) {
      console.log(province, 'true');
      return true;
    } else {
      return false;
    }

  }

  onSelectProvinceName(provinceName) {
    console.log(provinceName);
    // this.provice = provinceName.province_name.trim();
  }


  getStudents(filter) {
    this.studentService.getStudents(filter)
    .subscribe(response => {
        this.statesWithFlags = response.data;
    });
  }

  getProvinces() {
    this.addressService.getProvinces()
    .subscribe(response => {
      this.provinces = response.data;
      // console.log(this.provinces);
    });
  }

  getAmphoes(province: string) {
    this.addressService.getAmphoe(province)
    .subscribe(response => {
      this.amphoes = response.data;
      this.amphoeLoading = true;
      // console.log(this.amphoes);
    });
  }

  onSelectProvince(province: any) {
    this.amphoeLoading = false;
    this.model = '';
    if(province === '1') {
      this.province = '';
      this.router.navigate([], { queryParams: {  } });
    } else {
      // this.selectedProvince = province;
      this.province = province;
      this.getAmphoes(province);
      this.router.navigate([], { queryParams: { provices: this.province, amphoes: null } });
    }

  }

  onSelectAmphoe(amphoe: string) {
    if(amphoe === '1') {
      console.log('select default amphoe');
      this.amphoe = '';
      this.router.navigate([], { queryParams: { provices: this.province } });
    } else {
      this.amphoe = amphoe;
      this.router.navigate([], { queryParams: { provices: this.province, amphoes: this.amphoe } });
    }
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map(term => term === '' ? []
        :  this.statesWithFlags.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  formatter = (x: {name: string}) => x.name;

  selectedItem(item){
    this.loading = true;
    this.idCard = item.item.idcard;

    if (this.province == null && this.amphoe == null) {
      console.log(this.province);
      this.router.navigate([], { queryParams: { student: this.idCard } });
    }
    if(this.province) {
      this.router.navigate([], { queryParams: { provices: this.province, student: this.idCard } });
    }
    if (this.amphoe) {
      this.router.navigate([], { queryParams: { provices: this.province, amphoes: this.amphoe, student: this.idCard } });
    }
  }

}

interface FilterStudent {
	idCard: string;
  province: string;
  amphoe: string;
}
