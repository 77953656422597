import { Component, OnInit } from '@angular/core';
import { SharedAnimations } from './../../../assets/animations/shared-animations';
import { StudentService } from './../../services/student.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [SharedAnimations]
})
export class HomeComponent implements OnInit {
  public studentType: any;
  lat: number = 14.500349;
  lng: number = 100.704206;
  zoom: number = 6;

  constructor(private studentService: StudentService) { }

  ngOnInit(): void {
    this.studentService.getStudentType()
    .subscribe(response => {
      this.studentType = response.data;
      console.log(this.studentService);
    });
  }

}
