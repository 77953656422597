import { Component, OnInit, OnChanges } from '@angular/core';
import { StudentService } from './../../services/student.service';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-maps-search',
  templateUrl: './maps-search.component.html',
  styleUrls: ['./maps-search.component.scss']
})
export class MapsSearchComponent implements OnInit {
  public lat: number;
  public lng: number;
  previous;
  zoom: number = 10;
  public students: any;
  private studentsSub: Subscription;
  public studentMap: marker[];
  public statesWithFlags: any;
  private idCard: string;
  private provice: string;
  private amphoe: string;
  clickedItem: [];

  public filter: FilterStudent;

  constructor(private studentService: StudentService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(params => {
      let idCard = params['student'];
      this.filter = {
        idCard: idCard
      }
      this.getStudents(this.filter);
      // this.getStudents(idCard);
       // Print the parameter to the console.
    });
  }

  getStudents(filter) {
    this.studentService.getStudents(filter)
    .subscribe(response => {
        this.students = response.data;
        this.lat = parseFloat(response.data[0].latitude);
        this.lng = parseFloat(response.data[0].longitude);
        console.log('lat: ' + this.lat + ' lng: ' + this.lng);
    });
  }

  // getStudents(filter) {
  //   this.studentService.getStudent(filter)
  //     .subscribe(response => {
  //       this.student = response.data;
  //       this.statesWithFlags = response.data;
  //       console.log(response.data);
  //   });
  // }


  mapClicked($event: MouseEvent) {
    // console.log($event.coords.lat);
  }

  clickedMarker(label: string, index: number, infowindow) {
	console.log(`clicked the marker: ${label || index}`)
    if (this.previous) {
        this.previous.close();
    }
    this.previous = infowindow;
 }

  markerDragEnd(m: marker, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }

  markers: marker[] = [
	  {
		  lat: 51.673858,
		  lng: 7.815982,
		  label: 'A',
		  draggable: true
	  },
	  {
		  lat: 51.373858,
		  lng: 7.215982,
		  label: 'B',
		  draggable: false
	  },
	  {
		  lat: 51.723858,
		  lng: 7.895982,
		  label: 'C',
		  draggable: true
	  }
  ]

  showStudentDetail(idCard: string) {
    console.log(idCard);
    this.router.navigate(['/students/' + idCard]);
  }


}

// just an interface for type safety.
interface marker {
	lat: number;
	lng: number;
	label?: string;
	draggable: boolean;
}

interface FilterStudent {
	idCard: string;
}





