<h3 class="font-weight-bold">การรับการสงเคราะห์</h3>
<div class="card">
    <div class="card-body">
        <h4 class="font-weight-bold">ทุนการศึกษาเรียนต่อ</h4>
        <p>ผู้ให้การสงเคราะห์ <span class="font-weight-bold">ศูนย์ช่วยเหลือผู้พิการ</span></p>
        <p>วันที่ได้รับ <span class="font-weight-bold">20 เมษายน 2563</span></p>
        <p>รายละเอียด</p>
        <p>รูปภาพ (ถ้ามี)</p>
    </div>
</div>
<div class="card">
    <div class="card-body">
        <h4 class="font-weight-bold">ทุนช่วยเหลือทางบ้าน</h4>
        <p>ผู้ให้การสงเคราะห์ <span class="font-weight-bold">บุคลากรศูนย์การศึกษา</span></p>
        <p>วันที่ได้รับ <span class="font-weight-bold">1 มการาคม 2562</span></p>
        <p>รายละเอียด</p>
        <p>รูปภาพ (ถ้ามี)</p>
    </div>
</div>