import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private searchChange = new BehaviorSubject('');
  searchParam = this.searchChange.asObservable();

  constructor() { }

  changeParams(data: any) {
    this.searchChange.next(data);
  }


}
