<div class="wrapper">
  <div class="container-fluid">
    <!-- section student count -->
    <div class="row section-student">
      <!-- all -->
      <div class="col-md-7">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <img [src]="['assets/img/4008746.jpg']" alt="kid">
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p>จำนวนเด็กพิเศษทั้งหมด</p>
                <h1 class="text-student d-inline-block mr-2 mb-0">350</h1><p class="d-inline-block">คน</p>
              </div>
              <div class="col-md-4">
                <p>เพศชาย</p>
                <h1 class="text-student d-inline-block mr-2 mb-0">250</h1><p class="d-inline-block">คน</p>
              </div>
              <div class="col-md-4">
                <p>เพศหญิง</p>
                <h1 class="text-student d-inline-block mr-2 mb-0">100</h1><p class="d-inline-block">คน</p>
              </div>
              <!-- <div class="col-md-4 stretch-card justify-content-end align-items-end">
                <div class="">
                  <p class="mb-0 text-right">เพศชาย</p>
                  <h4 class="d-inline-block font-weight-bold mr-2 mb-0">150</h4><p class="d-inline-block">คน</p>
                  <p class="mb-0 text-right">เพศหญิง</p>
                  <h4 class="d-inline-block font-weight-bold mr-2 mb-0">150</h4><p class="d-inline-block">คน</p>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- end all -->

      <!-- type -->
      <div class="col-md-5">
        <div class="row">
          <div class="col-12">
            <h3 class="mb-3">ประเภทความพิการ</h3>
            <p class="text-muted mb-4">โดยแบ่งจำนวนเด็กพิเศษตามประเภทความพิการ</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-6 stretch-card mb-3" *ngFor="let type of studentType">
            <div class="card card-type">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-7 col-12">
                    <p class="text-center text-md-left"><small>{{type.studentType}}</small></p>
                  </div>
                  <div class="col-md-5 col-12 text-center text-md-right">
                    <h1 class="text-gradient mb-0">{{type.count}}</h1>
                  <p class="text-muted"><small>คน</small></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end type -->
    </div>
    <!-- student map -->
    <div class="row section-map justify-content-between align-items-center">
      <div class="col-md-5">
        <div class="card card-map">
          <div class="card-body">
            <agm-map
          [latitude]="lat"
          [longitude]="lng"
          [zoom]="zoom"
          [disableDefaultUI]="true"
          [showDefaultInfoWindow]="false"
        >
        </agm-map>
          </div>
        </div>
        
      </div>
      <div class="col-md-7">
        <h2 class="font-weight-bold">แผนที่เด็กพิเศษ</h2>
        <p class="mt-4">
          Black Design comes with three pre-built pages to help you get
          started faster. You can change the text and images and you're
          good to go.
        </p>
        <button class="btn btn-danger btn-round mr-1" type="button" [routerLink]="['/maps']">
          <i class="tim-icons icon-map-big mr-1"></i> ดูแผนที่
        </button>
      </div>
      
    </div>
    
  </div>
</div>

<!-- <div class="container">
  <div *ngFor="let type of studentType">
    <label><h4>{{type.studentType}} </h4> <h5>จำนวน {{type.count}} คน</h5></label>
  </div>
</div> -->



