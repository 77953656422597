import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserGuard } from './grard/user.guard';

import { LoginComponent } from './pages/auth/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { MapsComponent } from './pages/maps/maps.component';
import { MapsSearchComponent } from './pages/maps-search/maps-search.component';
import { StudentDetailComponent } from './pages/students/student-detail/student-detail.component';
import { StudentLayoutComponent } from './shared/layouts/student-layout/student-layout.component';
import { IndexComponent } from './pages/index/index.component';

const routes: Routes = [
  // { path: '', redirectTo: 'maps', pathMatch: 'full' },
  // { path: '', component: IndexComponent },
  { path: '', component: IndexComponent },
  { path: 'maps', component: MapsComponent, canActivate: [UserGuard] },
  // { path: 'maps', component: MapsComponent, canActivate: [UserGuard] },
  { path: 'maps/search', component: MapsSearchComponent, canActivate: [UserGuard] },
  // { path: 'students/:idcard', component: StudentDetailComponent, canActivate: [UserGuard] },
  // { path: 'students/id', component: StudentLayoutComponent},
  {
    path: 'student/id',
    component: StudentLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './pages/student/student.module#StudentModule',

      }
    ],
    canActivate: [UserGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule],
  providers: [UserGuard]
})
export class AppRoutingModule { }
