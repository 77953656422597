import { Component, OnInit, OnDestroy } from "@angular/core";
import noUiSlider from "nouislider";
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/pages/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  isCollapsed = true;
  public userIsAuthenticated = false;
  public getAuthUser: any;
  private nameSub: Subscription;
  private authListenerSubs: Subscription;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    // auth
    this.getAuth();

    // header
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("index-page");
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("index-page");
  }

  //auth
  getAuth() {
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authListenerSubs = this.authService.getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
    });
  }

}
