import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { StudentService } from 'src/app/services/student.service';
import { AuthService } from '../auth/auth.service';
import { SharedAnimations } from 'src/assets/animations/shared-animations';

@Component({
  selector: 'app-maps-page',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss'],
  animations: [SharedAnimations]
})
export class MapsComponent implements OnInit {

  // user
  private userSub: Subscription;
  public user: any;
  // maps
  lat: number = 14.500349;
  lng: number = 100.704206;
  previous;
  zoom: number = 6;
  public studentCount: any;
  public student: any;
  public students: any;
  private studentsSub: Subscription;
  public studentMap: marker[];
  public statesWithFlags: any;
  clickedItem: [];
  public filter: FilterStudent;
  private idCard: string;
  private province: string;
  private amphoe: string;

  // student profile and search
  proviceLoading: boolean;
  studentLoading: boolean;
  searchLoading: boolean;
  formSearchLoading: boolean;
  tooltipStudent: string;
  tooltipSearch: string;

  // loading
  searchFormLoading: boolean;
  alert: boolean;
  alertIcon: string;
  alertText: string;
  btnReset: boolean;

  constructor(
    private authService: AuthService,
    private studentService: StudentService,
    private activatedRoute: ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.getUser();

    this.filter = {
      idCard: null,
      province: null,
      amphoe: null,
    }


    this.activatedRoute.queryParams.subscribe(params => {
      if (params['student'] != '') {
        let idCard = params['student'];
        this.idCard = idCard;
        this.filter = {
          idCard: this.idCard,
          province: this.province,
          amphoe: this.amphoe
        }
      }
      if (params['provices'] != '') {
        let province = params['provices'];
        this.province = province;
        this.filter = {
          idCard: this.idCard,
          province: this.province,
          amphoe: null
        }
      }
      if (params['amphoes'] != '') {
        let amphoe = params['amphoes'];
        this.amphoe = amphoe;
        this.filter = {
          idCard: this.idCard,
          province: this.province,
          amphoe: this.amphoe
        }
      }
      // console.log('select province ', this.filter);
      this.getStudents(this.filter);
      this.getAlert();
      if(this.filter.province == null) {
        this.proviceLoading = false;
      } else {
        this.proviceLoading = true;
      }
    });

  }

  getUser() {
    this.user = this.authService.getAuthName();
    this.userSub = this.authService.getAuthDataUpdateListener()
        .subscribe(response => {
          this.user = response;
    });
  }

  getStudents(filter) {
    console.log(filter);
    this.studentService.getStudents(filter)
    .subscribe(response => {
      if (this.filter.idCard != null) {
        this.studentLoading = true;
        this.student = response.data;
        console.log(this.student);
      }
      this.statesWithFlags = response.data;
      this.students = response.data;
      this.studentCount = this.students.length;
      if (this.students.length == 1) {
        this.lat = parseFloat(response.data[0].latitude);
        this.lng = parseFloat(response.data[0].longitude);
         this.zoom = 15;
      }
      console.log(this.studentCount);
    });
  }

  goHomeStudent(latitude, longitude) {
    const url = "https://www.google.com/maps/search/?api=1&query="+latitude+","+longitude;
    // this.router.navigate([url], n);
    window.open(url);
  }

  getStudent(filter) {
    this.studentService.getStudents(filter)
    .subscribe(response => {
        this.students = response.data;
        this.lat = parseFloat(response.data[0].latitude);
        this.lng = parseFloat(response.data[0].longitude);
        console.log('lat: ' + this.lat + ' lng: ' + this.lng);
    });
  }

  mapClicked($event: MouseEvent) {
    // console.log($event.coords.lat);
  }

  clickedMarker(label: string, index: number, infowindow) {
	console.log(`clicked the marker: ${label || index}`)
    if (this.previous) {
        this.previous.close();
    }
    this.previous = infowindow;
 }

  markerDragEnd(m: marker, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }

  markers: marker[] = [
	  {
		  lat: 51.673858,
		  lng: 7.815982,
		  label: 'A',
		  draggable: true
	  },
	  {
		  lat: 51.373858,
		  lng: 7.215982,
		  label: 'B',
		  draggable: false
	  },
	  {
		  lat: 51.723858,
		  lng: 7.895982,
		  label: 'C',
		  draggable: true
	  }
  ]

  showStudentDetail(idCard: string) {
    console.log(idCard);
    this.router.navigate(['/students/' + idCard]);
  }

  onChangeProfile() {
    // if(this.studentLoading == true) {
    //   this.studentLoading = false;
    //   this.tooltipStudent = 'ซ่อนข้อมูลนักเรียน';
    // } else {
    //   this.studentLoading = true;
    //   this.tooltipStudent = 'แสดงข้อมูลนักเรียน';
    // }
  }

  onChangeSearch() {
    if(this.searchLoading == true) {
      this.searchLoading = false;
      this.tooltipSearch = 'ซ่อนการค้นหา';
    } else {
      this.searchLoading = true;
      this.tooltipSearch = 'แสดงการค้นหา';
    }
  }

  getAlert() {
    if(this.filter.idCard != null || this.filter.province != null || this.filter.amphoe != null) {
      this.btnReset = true;
    }
  }
  onReset() {
      this.btnReset = false;
      this.studentLoading = false;
      this.router.navigate([], { queryParams: { provices: null, amphoes: null , student: null } });
      this.alert = true;
      this.alertIcon = 'icon-check-2';
      this.alertText = 'รีเซ็ตเรียบร้อยแล้ว';
      setTimeout(() => this.alert = false , 4000);
  }

}

// just an interface for type safety.
interface marker {
	lat: number;
	lng: number;
	label?: string;
	draggable: boolean;
}

interface FilterStudent {
	idCard: string;
  province: string;
  amphoe: string;
}
