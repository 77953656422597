<h3 class="font-weight-bold">ข้อมูลอาชีพ</h3>
<div class="card">
    <div class="card-body pb-0">
    <ul class="timeline m-2">
        <li class="timeline-item">
            <div class="timeline-info">
                <span>2560 - ปัจจุบัน</span>
            </div>
            <div class="timeline-marker"></div>
            <div class="timeline-content">
                <h4 class="timeline-title mb-0"><span>อาชีพ </span><span class="text-muted">ค้าขาย</span></h4>
                <p><span class="text-dark">สถานที่ทำงาน </span><span class="text-muted">จังหวัดกาฬสินธุ์</span></p>
            </div>
        </li>
        <li class="timeline-item">
            <div class="timeline-info">
                <span>2559 - 2560</span>
            </div>
            <div class="timeline-marker"></div>
            <div class="timeline-content">
                <h4 class="timeline-title mb-0"><span>อาชีพ </span><span class="text-muted">นักเรียน</span></h4>
                <p><span class="text-dark">สถานที่ทำงาน </span><span class="text-muted">ศูนย์การศึกษาพิเศษ ประจำจังหวัดกาฬสินธุ์</span></p>
            </div>
        </li>
    </ul>
    </div>
</div>