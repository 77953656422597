import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from './shared/shared.module';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './pages/auth/auth-interceptor';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { MapsComponent } from './pages/maps/maps.component';
import { SearchMapComponent } from './components/search-map/search-map.component';
import { MapsSearchComponent } from './pages/maps-search/maps-search.component';
import { StudentDetailComponent } from './pages/students/student-detail/student-detail.component';
import { HomeComponent } from './pages/home/home.component';
import { IndexComponent } from './pages/index/index.component';
import { StudentModule } from './pages/student/student.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    MapsComponent,
    SearchMapComponent,
    MapsSearchComponent,
    StudentDetailComponent,
    IndexComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      // please get your own API key here:
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
      apiKey: 'AIzaSyBK3eVMkJm3P9YKOhAUeRy0qVyfdW3u-Lg'
    }),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    NgbModule,
    SharedModule,
    StudentModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS , useClass: AuthInterceptor , multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
