<alerts *ngIf="alert" iconClass="{{alertIcon}}" [alertText]="alertText"></alerts>
<div class="login">
  <div class="login-container">
    <div class="login-container--card">
      <div class="card card-login p-3">
        <div class="card-body">
          <div class="text-center mb-4">
            <h3 class="mb-0 text-dark">เข้าสู่ระบบ</h3>
            <p class="text-muted"><small> กรุณากรอกชื่อผู้ใช้งานและรหัสผ่านของคุณให้ถูกต้อง </small></p>
          </div>
          <form class="form" (submit)="onLogin(formLogin)" #formLogin="ngForm">
            <div
              class="input-group"
              [ngClass]="{ 'input-group-focus': focus === true, 'has-danger': emailInput.invalid && (emailInput.dirty || emailInput.touched)}"
            >
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <i class="tim-icons icon-email-85"> </i>
                </div>
              </div>
              <input
                class="form-control"
                placeholder="อีเมลหรือชื่อผู้ใช้งาน"
                type="email"
                (focus)="focus = true"
                (blur)="focus = false"
                name="email"
                #emailInput="ngModel"
                required ngModel
              />
            </div>
            <!-- <p class="text-danger"><small *ngIf="error">{{error.email}}</small></p> -->
            <div
              class="input-group"
              [ngClass]="{ 'input-group-focus': focus1 === true, 'has-danger': passwordInput.invalid && (passwordInput.dirty || passwordInput.touched) }"
            >
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <i class="tim-icons icon-lock-circle"> </i>
                </div>
              </div>
              <input
                class="form-control"
                placeholder="รหัสผ่าน"
                type="password"
                (focus)="focus1 = true"
                (blur)="focus1 = false"
                name="password"
                #passwordInput="ngModel"
                required ngModel
              />
            </div>
            <!-- <p class="text-danger d-flex"><small *ngIf="error">* {{error.email}} {{error.password}}</small></p> -->
          <div class="text-center mt-3">
            <!-- <button type="submit" class="btn btn-danger btn-block btn-lg">
              เข้าสู่ระบบ
            </button> -->
            <btn-loading btnText="เข้าสู่ระบบ" btnClass="btn-danger btn-round btn-block btn-lg" [loading]="btnLoading"></btn-loading>
          </div>
          </form>
        </div>
      </div>
      <div class="text-center px-4">
        <button
        type="button"
            class="btn btn-danger btn-neutral btn-round btn-block btn-lg"
            data-target="#myModal"
            (click)="myModal.show()"
          >
          <i class="tim-icons icon-send mr-1"></i> ส่งคำขอเพื่อเข้าใช้งาน
        </button>
      </div>
    </div>
    <div class="login-container--footer">
      <div class="row">
        <div class="col-md-6 col-12">
          <p class="text-center text-md-left mb-0"><small><span class="font-weight-bold"> © 2020 ศูนย์การศึกษาพิเศษ ประจำจังหวัดกาฬสินธุ์ </span> Kalasin Special Education Center. </small></p>
        </div>
        <div class="col-md-6 col-12">
          <div class="d-flex justify-content-center justify-content-md-end">
            <ul class="list-unstyled">
              <li><a href=""><small> วิธีการใช้งาน </small></a></li>
              <li><a href=""><small> ส่งคำขอเข้าใช้งาน </small></a></li>
              <li><a href=""><small> เกี่ยวกับเรา </small></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  aria-hidden="true"
  aria-labelledby="myModalLabel"
  bsModal
  class="modal fade modal-black"
  #myModal="bs-modal"
  id="myModal"
  role="dialog"
  tabindex="-1"
>
<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header justify-content-center">
      <button
        aria-hidden="true"
        class="close"
        data-dismiss="modal"
        (click)="myModal.hide()"
        type="button"
      >
        <i class="tim-icons icon-simple-remove text-dark"> </i>
      </button>
      <div class="text-center ml-auto mr-auto mt-4">
        <h4 class="mb-0 text-dark">ส่งคำขอเพื่อเข้าใช้งาน</h4>
        <p class="text-muted"><small> กรุณากรอกข้อมูลให้ครบถ้วน เพื่อขอเข้าใข้งานระบบ </small></p>
      </div>
    </div>
    <div class="modal-body">
      <form role="form">
        <div class="form-group mb-3">
          <div
            class="input-group input-group-alternative"
            [ngClass]="{ 'input-group-focus': focus1 === true }"
          >
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="tim-icons icon-email-85"> </i>
              </span>
            </div>
            <input
              class="form-control"
              placeholder="Email"
              type="email"
              (focus)="focus1 = true"
              (blur)="focus1 = false"
            />
          </div>
        </div>
        <div class="form-group">
          <div
            class="input-group input-group-alternative"
            [ngClass]="{ 'input-group-focus': focus2 === true }"
          >
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="tim-icons icon-key-25"> </i>
              </span>
            </div>
            <input
              class="form-control"
              placeholder="Password"
              type="password"
              (focus)="focus2 = true"
              (blur)="focus2 = false"
            />
          </div>
        </div>
        <div class="form-check mt-3">
          <label class="form-check-label">
            <input
              checked="checked"
              class="form-check-input"
              type="checkbox"
            />

            <span class="form-check-sign"> </span> Remember me!
          </label>
        </div>
        <div class="text-center">
          <button class="btn btn-primary my-4" type="button">
            Sign in
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
</div>
