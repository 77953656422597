import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment.prod';
const BACKEND_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(private http: HttpClient) { }

  getProvinces() {
    return this.http.get<{data: any}>(BACKEND_URL + '/provinces');
  }

  findProvinceName(provinceName: string) {
    const data = {
      province_name: provinceName
    };
    return this.http.post<{data: any}>(BACKEND_URL + '/provinces/find-name', data);
  }

  getAmphoe(provinceName: string) {
    const data = {province_name: provinceName};
    return this.http.post<{data: any}>(BACKEND_URL + '/amphoes', data);
  }
}
