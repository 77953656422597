import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  public auth: boolean;
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    console.log(this.auth);
    this.authService.autoAuthUser();
    this.auth = this.authService.getIsAuth();
    console.log(this.auth);
      // if (!isAuth) {
      //   this.router.navigate(['/']);
      // }
  }

}
