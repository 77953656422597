import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StudentEducationComponent } from './student-education/student-education.component';
import { StudentTreatmentComponent } from './student-treatment/student-treatment.component';
import { StudentAssistanceComponent } from './student-assistance/student-assistance.component';
import { StudentFamilyComponent } from './student-family/student-family.component';
import { StudentCareerComponent } from './student-career/student-career.component';
import { StudentForwardingComponent } from './student-forwarding/student-forwarding.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/student/id/education',
    pathMatch: 'full'
  },
  {
    path: 'education',
    component: StudentEducationComponent
  },
  {
    path: 'treatment',
    component: StudentTreatmentComponent
  },
  {
    path: 'assistance',
    component: StudentAssistanceComponent
  },
  {
    path: 'family',
    component: StudentFamilyComponent
  },
  {
    path: 'career',
    component: StudentCareerComponent
  },
  {
    path: 'forwarding',
    component: StudentForwardingComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StudentRoutingModule { }
