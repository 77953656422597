import { Component, OnInit, Input } from '@angular/core';
import { SharedAnimations } from 'src/assets/animations/shared-animations';

@Component({
  selector: 'btn-loading',
  templateUrl: './btn-loading.component.html',
  styleUrls: ['./btn-loading.component.scss'],
  animations: [SharedAnimations]
})
export class BtnLoadingComponent implements OnInit {
  @Input('loading') loading: boolean;
  @Input('btnClass') btnClass: string;
  @Input('btnText') btnText: string;
  @Input('loadingText') loadingText = 'รอสักครู่ ...';
  @Input('type') type: 'button' | 'submit' = 'submit';

  constructor() { }

  ngOnInit(): void {
  }

}
