<div class="card card-profile mb-2" [@animate]="{value:'*',params:{opacity:'0', duration: '600ms'}}">
  <div class="card-body">
    <div class="card-body--header">
      <div class="row">
        <div class="col-4">
          <img
          alt="Raised circle image"
          class="img-fluid rounded-circle shadow-lg"
          [src]="image"
        />
        </div>
        <div class="col-8">
          <h5 class="font-weight-bold mb-1">{{name}} {{id}}</h5>
          <h6 class="mb-2"><span class="font-weight-bold"> ชื่อเล่น </span> {{nickName}} &bull; <span class="font-weight-bold"> เพศ </span> {{sex}}</h6>
          <p class="overflow-hidden"><span class="badge badge-neutral">{{studentType}}</span></p>
        </div>
      </div>
    </div>
    <div class="card-body--description">
      <div class="card-scroll">
        <h6>ข้อมูลทั่วไป</h6>
        <dl class="row">
          <dt class="col-sm-2"><i class="fas fa-user" placement="left" tooltip="ประเภทความพิการ"></i></dt>
          <dd class="col-sm-10"><small> {{studentType}} {{id}}</small></dd>
          <dt class="col-sm-2"><i class="fab fa-get-pocket" placement="left" tooltip="ความสามารถ"></i></dt>
          <dd class="col-sm-10"><small> {{ability}} </small></dd>
          <dt class="col-sm-2"><i class="fas fa-address-book" placement="left" tooltip="หมายเลขบัตรประชาชน"></i></dt>
          <dd class="col-sm-10"><small> {{idCard}} </small></dd>
          <dt class="col-sm-2"><i class="far fa-calendar" placement="left" tooltip="วันเกิด"></i></dt>
          <dd class="col-sm-10"><small> {{birthday}} </small></dd>
          <dt class="col-sm-2"><i class="fas fa-male ml-1" placement="left" tooltip="บิดา"></i></dt>
          <dd class="col-sm-10"><small> {{fatherName}} </small></dd>
          <dt class="col-sm-2"><i class="fas fa-female ml-1" placement="left" tooltip="มารดา"></i></dt>
          <dd class="col-sm-10"><small> {{motherName}} </small></dd>
        </dl>
        <h6>
          ข้อมูลที่อยู่
        </h6>
        <dl class="row">
          <dt class="col-sm-2"><i class="fas fa-map" placement="left" tooltip="ที่อยู่"></i></dt>
          <dd class="col-sm-10"><small> {{address}} ต.{{district}} อ.{{amphoe}} จ.{{province}} {{zipcode}}</small>
          <p><a href="javascript:void(0)" type="button" class="btn btn-danger btn-neutral btn-sm p-0"><small> ไปยังแผนที่ <i class="tim-icons icon-minimal-right"></i> </small></a></p>
          </dd>
        </dl>
      </div>
      <div class="row">
        <!-- <div class="col-6">
          <button class="btn btn-round btn-danger btn-block">ดูข้อมูล</button>
        </div> -->
        <div class="col">
          <a href="javascript:void(0)" [routerLink]="['/student/id/']" type="button" class="btn btn-round btn-danger btn-neutral btn-block">ดูข้อมูล</a>
        </div>
        <!-- <div class="col">
          <button class="btn btn-round btn-danger btn-neutral btn-block">แผนที่</button>
        </div> -->
      </div>
    </div>
  </div>
</div>

