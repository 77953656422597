import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment.prod';
import { Student } from "./../models/student.model";
import { BehaviorSubject } from 'rxjs';
const BACKEND_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class StudentService {
  private students: any;
  private student: Student[] = [];
  private studentsUpdated = new BehaviorSubject('');
  constructor(private http: HttpClient) { }

  getStudentUpdateListener() {
    return this.studentsUpdated.asObservable();
  }

  // getStudents(data: any) {
  //   this.http.post<{data: any}>(BACKEND_URL + '/students', data)
  //     .subscribe(response => {
  //       this.students = response.data;
  //       this.studentsUpdated.next(this.students);
  //     }, error => {
  //       console.log(error);
  //     });
  //   // return this.http.post<{data: any}>(BACKEND_URL + '/students', data);
  // }

  getStudents(data: any) {
    return this.http.post<{data: any}>(BACKEND_URL + '/students', data);
  }

  getStudent(id) {
    return this.http.get<{data: Student}>(BACKEND_URL + '/students/' + id);
  }

  getStudentType() {
    return this.http.get<{data: Student}>(BACKEND_URL + '/students-type');
  }

}
