<div class="wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4 col-lg-2">
        <div class="row">
          <!-- profile -->
          <div class="col-12">
            <div class="d-flex justify-content-center">
              <img
              alt="Raised circle image"
              class="img-fluid rounded-circle shadow"
              [src]="['assets/img/12716aee2e90d91df594519460b19e27.jpg']"
              width="160"
              height="160"
              />
            </div>
            <div class="text-center mt-3">
              <h4 class="font-weight-bold mb-0">ยอดเจริญ ไกยเดช</h4>
              <p><span class="dot dot-five mr-2"></span><small class="text-muted"> ความบกพร่องทางสติปัญญา</small></p>        
            </div>
          </div>
          <!-- end profile -->
          <!-- navigation -->
          <div class="col-12 mt-5">
            <div class="d-flex justify-content-center">
              <nav class="nav flex-column nav-pills">
                <a class="nav-link" href="#" routerLink="/student/id/education" routerLinkActive="active"><i class="tim-icons icon-bank mr-2"> </i>  ข้อมูลการศึกษา</a>
                <a class="nav-link" href="#" routerLink="/student/id/treatment" routerLinkActive="active"><i class="tim-icons icon-sound-wave mr-2"> </i>  การรับการรักษา</a>
                <a class="nav-link" href="#" routerLink="/student/id/assistance" routerLinkActive="active"><i class="tim-icons icon-gift-2 mr-2"> </i>  การรับการสงเคราะห์</a>
                <a class="nav-link" href="#" routerLink="/student/id/family" routerLinkActive="active"><i class="tim-icons icon-heart-2 mr-2"> </i>  ข้อมูลครอบครัว</a>
                <a class="nav-link" href="#" routerLink="/student/id/career" routerLinkActive="active"><i class="tim-icons icon-single-02 mr-2"> </i>  ข้อมูลอาชีพ</a>
                <a class="nav-link" href="#" routerLink="/student/id/forwarding" routerLinkActive="active"><i class="tim-icons icon-send mr-2"> </i>  ข้อมูลการส่งต่อ</a>
                <!-- <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a> -->
              </nav>
            </div>
          </div>
          <!-- end navigation -->
        </div>
      </div>
      
      <div class="col-md-8 col-lg-10">
        <!-- <div class="row">
          <div class="col-12">
            text
          </div>
        </div> -->
        <div class="row">
          <div class="col-lg-8">
            <!-- <h3 class="font-weight-bold">ข้อมูลการศึกษา</h3>
            <div class="card card-general">
              <div class="card-body">
                <p class="card-title">text</p>
              </div>
            </div> -->
            <router-outlet></router-outlet>
          </div>
          <div class="col-lg-4">
            <h4 class="font-weight-bold">ข้อมูลทั่วไป</h4>
            <hr/>
            <dl class="row">
              <dt class="col-sm-4">ชื่อจริง</dt>
              <dd class="col-sm-8">เด็กชายยอดเจริญ ไกยเดช</dd>
              <dt class="col-sm-4">ชื่อเล่น</dt>
              <dd class="col-sm-8">ยอด</dd>
              <dt class="col-sm-4">ความพิการ</dt>
              <dd class="col-sm-8">ความบกพร่องทางสติปัญญา</dd>
              <dt class="col-sm-4">ความสามารถ</dt>
              <dd class="col-sm-8">ไม่อยู่นิ่ง ไม่ยอมพูด</dd>
              <dt class="col-sm-4">บัตรประชาชน</dt>
              <dd class="col-sm-8">1449901072331</dd>
              <dt class="col-sm-4">วันเกิด</dt>
              <dd class="col-sm-8">9 สิงหาคม 2553</dd>
              <dt class="col-sm-4">ผู้ปกครอง</dt>
              <dd class="col-sm-8">นายบริรักษ์	แก้วอาษา</dd>
              <dt class="col-sm-4">ความเกี่ยวข้อง</dt>
              <dd class="col-sm-8">บิดา</dd>
              <dt class="col-sm-4">ครูผู้ดูแล</dt>
              <dd class="col-sm-8">นายสุทธิ หินวิเศษ</dd>
              <dt class="col-sm-4">เขตพื้นที่</dt>
              <dd class="col-sm-8">อำเภอห้วยเม็ก</dd>
              <dt class="col-sm-4">ที่อยู่</dt>
              <dd class="col-sm-8">39 หมู่ที่ 6 ตำบลลำพาน อำเภอเมืองจังหวัดกาฬสินธุ์ 46000</dd>
            </dl>
            <h4 class="font-weight-bold">แผนที่บ้าน</h4>
            <hr/>
            <div class="card">
                <agm-map
                  [latitude]="lat"
                  [longitude]="lng"
                  [zoom]="zoom"
                  [disableDefaultUI]="true"
                  [showDefaultInfoWindow]="false"
                >
                </agm-map>
            </div>
            <h4 class="font-weight-bold">ประเภทความพิการ</h4>
            <hr />
            <dl class="row">
              <dd class="col-sm-12"><span class="dot dot-one mr-2"></span><small class="text-muted"> ความบกพร่องทางการเห็น</small></dd>
              <dd class="col-sm-12"><span class="dot dot-two mr-2"></span><small class="text-muted"> ความบกพร่องทางการได้ยิน</small></dd>
              <dd class="col-sm-12"><span class="dot dot-three mr-2"></span><small class="text-muted"> ความบกพร่องทางร่างกาย หรือการเคลื่อนไหว หรือสุขภาพ</small></dd>
              <dd class="col-sm-12"><span class="dot dot-four mr-2"></span><small class="text-muted"> ความบกพร่องทางพฤติกรรม หรืออารมณ์</small></dd>
              <dd class="col-sm-12"><span class="dot dot-five mr-2"></span><small class="text-muted"> ความบกพร่องทางสติปัญญา</small></dd>
              <dd class="col-sm-12"><span class="dot dot-six mr-2"></span><small class="text-muted"> ความบกพร่องทางการเรียนรู้</small></dd>
              <dd class="col-sm-12"><span class="dot dot-seven mr-2"></span><small class="text-muted"> ออทิสติก</small></dd>
              <dd class="col-sm-12"><span class="dot dot-nine mr-2"></span><small class="text-muted"> พิการซ้อน</small></dd>
              <dd class="col-sm-12"><span class="dot dot-ten mr-2"></span><small class="text-muted"> ความบกพร่องทางการพูดและภาษา</small></dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

