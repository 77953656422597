<div class="card card-user d-inline-flex mr-2">
  <div class="card-body">
    <div class="row">
      <div class="col-3 card-body--left">
        <div class="card-body--icon">
          <i class="tim-icons icon-single-02"></i>
        </div>
      </div>
      <div class="col-9 card-body--right">
        <h5 class="mb-2"><span class="card-title mr-1">ยินดีต้อนรับ!</span> <span class="font-weight-light text-dark">{{name}}</span></h5>
        <h6 class="font-weight-light mb-0">เวลา {{ time | date: 'hh:mm:ss' }} น. | วันที่ {{ time | date: 'd' }} {{month}} {{year}} </h6>
      </div>
    </div>
  </div>
</div>
