import { Component, OnInit } from '@angular/core';
import { SharedAnimations } from 'src/assets/animations/shared-animations';

@Component({
  selector: 'app-student-layout',
  templateUrl: './student-layout.component.html',
  styleUrls: ['./student-layout.component.scss'],
  animations: [SharedAnimations]
})
export class StudentLayoutComponent implements OnInit {
  lat: number = 14.500349;
  lng: number = 100.704206;
  zoom: number = 6;
  constructor() { }

  ngOnInit(): void {
  }
}
