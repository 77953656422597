import { Component, OnInit, Input } from '@angular/core';
import { SharedAnimations } from 'src/assets/animations/shared-animations';

@Component({
  selector: 'btn-hidden',
  templateUrl: './btn-hidden.component.html',
  styleUrls: ['./btn-hidden.component.scss'],
  animations: [SharedAnimations]
})
export class BtnHiddenComponent implements OnInit {
  @Input('hidden') hidden: boolean;
  @Input('btnClass') btnClass: string;
  @Input('btnIcon') btnIcon: string;
  @Input('tooltipText') tooltipText: string;

  constructor() { }

  ngOnInit(): void {
  }

}
