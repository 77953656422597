import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedAnimations } from 'src/assets/animations/shared-animations';

@Component({
  selector: 'alerts',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [SharedAnimations]
})
export class AlertComponent implements OnInit {
  @Input('alert') alert: boolean;
  @Input('iconClass') iconClass: string;
  @Input('alertText') alertText : string;

  constructor() { }

  ngOnInit(): void {
  }

}
