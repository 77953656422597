import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { TabsModule } from "ngx-bootstrap/tabs";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { AlertModule } from "ngx-bootstrap/alert";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { ModalModule } from "ngx-bootstrap/modal";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";
import { PopoverModule } from "ngx-bootstrap/popover";
import { AgmCoreModule } from '@agm/core';

import { HeaderComponent } from './components/header/header.component';
import { LoadingComponent } from './components/loading/loading.component';
import { AlertComponent } from './components/alert/alert.component';
import { BtnLoadingComponent } from './components/btn-loading/btn-loading.component';
import { CardProfileComponent } from './components/card-profile/card-profile.component';
import { BtnHiddenComponent } from './components/btn-hidden/btn-hidden.component';
import { CardUserComponent } from './components/card-user/card-user.component';
import { CardDetailComponent } from './components/card-detail/card-detail.component';
import { StudentLayoutComponent } from './layouts/student-layout/student-layout.component';

const components = [
  HeaderComponent,
    LoadingComponent,
    AlertComponent,
    BtnLoadingComponent,
    CardProfileComponent,
    BtnHiddenComponent,
    CardUserComponent,
    CardDetailComponent,
    StudentLayoutComponent
];

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    RouterModule,
    AgmCoreModule.forRoot({
      // please get your own API key here:
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
      apiKey: 'AIzaSyBK3eVMkJm3P9YKOhAUeRy0qVyfdW3u-Lg'
    }),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    // ProgressbarModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    CollapseModule.forRoot(),
    // JwBootstrapSwitchNg2Module,
    // TabsModule.forRoot(),
    // PaginationModule.forRoot(),
    AlertModule.forRoot(),
    // BsDatepickerModule.forRoot(),
    // CarouselModule.forRoot(),
    // ModalModule.forRoot()
  ],
  declarations: components,
  exports: components
})
export class SharedModule { }
