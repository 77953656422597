<div class="section-loading" *ngIf="!loading" [@animate]="{value:'*',params:{opacity:'0', duration: '100ms'}}">
  <div class="loading">
    <div class="loading-position">
      <div class="loading--logo">
        <img src="assets/img/loading.png" width="120" class="unselectable">
        <!-- <h2 class="mb-0">SKS</h2>
        <p>Special Kalasin Student</p> -->
      </div>
      <div class="loading--waiting">
        <!-- <div class="lds-ripple"><div></div><div></div></div> -->
        <div class="loadingio-spinner-ball-fw4j9hgv0ze"><div class="ldio-vj93oc63gb">
          <div></div>
          </div></div>
      </div>
      <div class="loading--text">
        <small>กรุณารอสักครู่ ...</small>
      </div>
    </div>
    <!-- <div class="loading--logo">
      <h2 class="mb-0">SKS</h2>
      <p>Special Kalasin Student</p>
    </div>
    <div class="loading--waiting">
      <div class="lds-ripple"><div></div><div></div></div>
    </div> -->
    <!-- <div class="item-1"></div>
    <div class="item-2"></div>
    <div class="item-3"></div>
    <div class="item-4"></div>
    <div class="item-5"></div> -->
  </div>
</div>
