<h3 class="font-weight-bold">ข้อมูลครอบครัว</h3>
<div class="card">
    <div class="card-body">
        <h4 class="font-weight-bold">ข้อมูลผู้ปกครอง</h4>
        <div class="row mt-4">
            <div class="col-lg-6">
                <h5>
                    ชื่อ - นามสกุล
                    <small class="ml-2 text-muted">นายบริรักษ์ แก้วอาษา</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    ความเกี่ยวข้อง
                    <small class="ml-2 text-muted">บิดา</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    อาชีพ
                    <small class="ml-2 text-muted">เกษตกร</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    ระดับการศึกษา
                    <small class="ml-2 text-muted">มัธยมปลาย</small>
                </h5>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h5>
                    วันเกิด
                    <small class="ml-2 text-muted">3 มกราคม 2521</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    อายุ
                    <small class="ml-2 text-muted">42 ปี</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    โทรศัพท์
                    <small class="ml-2 text-muted">0981349001</small>
                </h5>
            </div>
            <div class="col-lg-12">
                <h5>
                    ที่อยู่ปัจจุบัน
                    <small class="ml-2 text-muted">39 หมู่ที่ 6 ตำบลลำพาน อำเภอเมืองจังหวัดกาฬสินธุ์ รหัสไปรษณีย์ 46000</small>
                </h5>
            </div>
        </div>
    </div>
</div>
<div class="card">
    <div class="card-body">
        <h4 class="text-father mb-4">ข้อมูลบิดา</h4>
        <div class="row mb-3">
            <div class="col-lg-6">
                <h5>
                    ชื่อ - นามสกุล
                    <small class="ml-2 text-muted">นายบริรักษ์ แก้วอาษา</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    สถานะภาพ
                    <small class="ml-2 text-muted">ยังมีชีวิต</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    สถานะ
                    <small class="ml-2 text-muted">แต่งงานแล้ว</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    อาชีพ
                    <small class="ml-2 text-muted">เกษตกร</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    ระดับการศึกษา
                    <small class="ml-2 text-muted">มัธยมปลาย</small>
                </h5>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-6">
                <h5>
                    สัญชาติ
                    <small class="ml-2 text-muted">ไทย</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    เชื้อชาติ
                    <small class="ml-2 text-muted">ไทย</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    ศาสนา
                    <small class="ml-2 text-muted">พุทธ</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    วันเกิด
                    <small class="ml-2 text-muted">3 มกราคม 2521</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    อายุ
                    <small class="ml-2 text-muted">42 ปี</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    ภูมิลำเนา
                    <small class="ml-2 text-muted">จังหวัดกาฬสินธุ์</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    บิดา
                    <small class="ml-2 text-muted">นายเกรียงไกร แก้วอาษา</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    มารดา
                    <small class="ml-2 text-muted">นางนราภรณ์ แก้วอาษา</small>
                </h5>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h5>
                    โทรศัพท์
                    <small class="ml-2 text-muted">0981349001</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    อีเมล
                    <small class="ml-2 text-muted">-</small>
                </h5>
            </div>
            <div class="col-lg-12">
                <h5>
                    ที่อยู่ปัจจุบัน
                    <small class="ml-2 text-muted">39 หมู่ที่ 6 ตำบลลำพาน อำเภอเมืองจังหวัดกาฬสินธุ์ รหัสไปรษณีย์ 46000</small>
                </h5>
            </div>
        </div>
    </div>
</div>
<div class="card">
    <div class="card-body">
        <h4 class="text-mother mb-4">ข้อมูลมารดา</h4>
        <div class="row mb-3">
            <div class="col-lg-6">
                <h5>
                    ชื่อ - นามสกุล
                    <small class="ml-2 text-muted">นางสาวนิตยา ถิ่นสถิตย์</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    สถานะภาพ
                    <small class="ml-2 text-muted">ยังมีชีวิต</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    สถานะ
                    <small class="ml-2 text-muted">แต่งงานแล้ว</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    อาชีพ
                    <small class="ml-2 text-muted">แม่บ้าน</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    ระดับการศึกษา
                    <small class="ml-2 text-muted">ปวส.</small>
                </h5>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-6">
                <h5>
                    สัญชาติ
                    <small class="ml-2 text-muted">ไทย</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    เชื้อชาติ
                    <small class="ml-2 text-muted">ไทย</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    ศาสนา
                    <small class="ml-2 text-muted">พุทธ</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    วันเกิด
                    <small class="ml-2 text-muted">18 ธันวาคม 2530</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    อายุ
                    <small class="ml-2 text-muted">33 ปี</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    ภูมิลำเนา
                    <small class="ml-2 text-muted">จังหวัดมหาสารคาม</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    บิดา
                    <small class="ml-2 text-muted">นายชาญชัย ถิ่นสถิตย์</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    มารดา
                    <small class="ml-2 text-muted">นางแจ่มจันทร์ ถิ่นสถิตย์</small>
                </h5>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <h5>
                    โทรศัพท์
                    <small class="ml-2 text-muted">0981349001</small>
                </h5>
            </div>
            <div class="col-lg-6">
                <h5>
                    อีเมล
                    <small class="ml-2 text-muted">-</small>
                </h5>
            </div>
            <div class="col-lg-12">
                <h5>
                    ที่อยู่ปัจจุบัน
                    <small class="ml-2 text-muted">39 หมู่ที่ 6 ตำบลลำพาน อำเภอเมืองจังหวัดกาฬสินธุ์ รหัสไปรษณีย์ 46000</small>
                </h5>
            </div>
        </div>
    </div>
</div>