import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { StudentService } from './../../../services/student.service';

@Component({
  selector: 'app-student-detail',
  templateUrl: './student-detail.component.html',
  styleUrls: ['./student-detail.component.scss']
})
export class StudentDetailComponent implements OnInit {
  public student: any;
  constructor(
    private route: ActivatedRoute, 
    private studentService: StudentService,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((paraMap: ParamMap) => {
      if (paraMap.has('idcard')) {
        const idcard = paraMap.get('idcard');
        this.getStudents(idcard)
      }
    });
  }

  getStudents(filter) {
    this.studentService.getStudent(filter)
      .subscribe(response => {
        this.student = response.data;
        console.log(response.data);
    });
  }

}
