import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StudentRoutingModule } from './student-routing.module';
import { StudentEducationComponent } from './student-education/student-education.component';
import { StudentTreatmentComponent } from './student-treatment/student-treatment.component';
import { StudentAssistanceComponent } from './student-assistance/student-assistance.component';
import { StudentFamilyComponent } from './student-family/student-family.component';
import { StudentCareerComponent } from './student-career/student-career.component';
import { StudentForwardingComponent } from './student-forwarding/student-forwarding.component';


@NgModule({
  declarations: [StudentEducationComponent, StudentTreatmentComponent, StudentAssistanceComponent, StudentFamilyComponent, StudentCareerComponent, StudentForwardingComponent],
  imports: [
    CommonModule,
    StudentRoutingModule
  ]
})
export class StudentModule { }
