<!-- <app-loading *ngIf="!students"></app-loading> -->

<div class="maps">
  <div class="map-location">
    <agm-map
      [latitude]="lat"
      [longitude]="lng"
      [zoom]="zoom"
      [disableDefaultUI]="true"
    >
      <agm-marker
          *ngFor="let m of students; let i = index"
          (markerClick)="clickedMarker(m.name, i, infowindow)"
          [latitude]="m.latitude"
          [longitude]="m.longitude"
          [markerDraggable]="false">

        <agm-info-window #infowindow class="bg-danger">
          <div class="d-flex justify-content-center mb-3"><img [src]="m.image" class="img-fluid rounded-circle shadow" width="80" height="80"></div>
            <h5 class="font-weight-bold text-center mb-1"><a href="javascript:void(0)" class="text-dark" (click)="showStudentDetail(m.idcard)">{{m?.name}}</a></h5>
            <p class="text-center">
              <a class="badge badge-danger" href="#">
                ไปบ้านนักเรียน <i class="tim-icons icon-minimal-right ml-1"></i>
              </a>
            </p>
          <!-- <a href="javascript:void(0)" (click)="showStudentDetail(m.idcard)" ><strong>{{m?.name}}</strong></a><br>
          <a href=""><strong>ไปบ้านนักเรียน</strong></a> -->
        </agm-info-window>
      </agm-marker>
    </agm-map>
  </div>
  <div class="map-section">
    <div class="map-section--top">
      <app-search-map></app-search-map>
    </div>
    <div class="map-section--bottom">
      <button type="button" class="btn btn-danger btn-icon btn-round btn-lg mr-1">
        <i class="tim-icons icon-zoom-split"> </i>
      </button>
    </div>
  </div>
</div>

