import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})



export class LoginComponent implements OnInit {

  private errorStatusSub: Subscription;
  public error: Error;
  public errorMessage: string;
  focus;
  focus1;
  focus2;
  alert: boolean;
  alertIcon: string;
  alertText: string;
  btnLoading: boolean;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.errorStatusSub = this.authService.getErrorListener().subscribe(
      response => {
        console.log(response.code);
        if(response.code === 422) {
          this.error = {email: response.error.email, password: response.error.password};
        }
        if(response.code === 401) {
          // this.errorMessage = response.error;
          this.btnLoading = false;
          this.alert = true;
          this.alertIcon = 'icon-alert-circle-exc';
          this.alertText = response.error;
          setTimeout(() => this.alert = false , 4000);
        }
        // console.log(this.error);
        // this.alertService.danger(this.error.username);
        // this.isLoading = false;
      }
    );
  }

  onLogin(form: NgForm) {
    if(form.invalid) {
      this.alert = true;
      this.alertIcon = 'icon-alert-circle-exc';
      this.alertText = 'กรุณากรอกข้อมูลอีเมลของคุณหรือรหัสผ่าน';
      setTimeout(() => this.alert = false , 4000);
      return;
    }
    this.btnLoading = true;
    this.authService.login(form.value.email, form.value.password);
  }

}
interface Error {
  email: string;
  password: string;
};
