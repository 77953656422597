<h3 class="font-weight-bold">ข้อมูลการศึกษา</h3>
<div class="card">
    <div class="card-body pb-0">
        <h4 class="font-weight-bold mb-4">ข้อมูลด้านการแพทย์</h4>
        <dl class="row">
            <dt class="col-sm-4">สถานศึกษา</dt>
            <dd class="col-sm-8">ศรีสังวาลย์ ขอนแก่น</dd>
            <dt class="col-sm-4">ระดับชั้น</dt>
            <dd class="col-sm-8">ประถมศึกษาปีที่ 3</dd>
            <dt class="col-sm-4">ปีที่ศึกษา</dt>
            <dd class="col-sm-8">2561</dd>
          </dl>
    </div>
</div>