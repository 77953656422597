import { Component, OnInit, Input } from '@angular/core';
import { SharedAnimations } from 'src/assets/animations/shared-animations';

@Component({
  selector: 'app-card-detail',
  templateUrl: './card-detail.component.html',
  styleUrls: ['./card-detail.component.scss'],
  animations: [SharedAnimations]
})
export class CardDetailComponent implements OnInit {
  @Input('province') province: string;
  @Input('amphoe') amphoe: string;
  @Input('studentCount') studentCount: string;

  constructor() { }

  ngOnInit(): void {
  }

}
