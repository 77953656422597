import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-student-treatment',
  templateUrl: './student-treatment.component.html',
  styleUrls: ['./student-treatment.component.scss']
})
export class StudentTreatmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
