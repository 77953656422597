import { Component, OnInit, Input } from '@angular/core';
import { SharedAnimations } from 'src/assets/animations/shared-animations';

@Component({
  selector: 'app-card-profile',
  templateUrl: './card-profile.component.html',
  styleUrls: ['./card-profile.component.scss'],
  animations: [SharedAnimations]
})
export class CardProfileComponent implements OnInit {
  @Input('image') image: string;
  @Input('id') id: number;
  @Input('name') name: string;
  @Input('nickName') nickName: string;
  @Input('sex') sex: string;
  @Input('studentType') studentType: string;
  @Input('ability') ability: string;
  @Input('idCard') idCard: string;
  @Input('birthday') birthday: string;
  @Input('fatherName') fatherName: string;
  @Input('motherName') motherName: string;
  @Input('address') address: string;
  @Input('district') district: string;
  @Input('amphoe') amphoe: string;
  @Input('province') province: string;
  @Input('zipcode') zipcode: string;

  constructor() { }

  ngOnInit(): void {
  }
}
