<app-loading *ngIf="!students"></app-loading>
<alerts *ngIf="alert" [iconClass]="alertIcon" [alertText]="alertText"></alerts>
<div class="maps">
  <div class="map-location">
    <agm-map
      [latitude]="lat"
      [longitude]="lng"
      [zoom]="zoom"
      [disableDefaultUI]="true"
      [showDefaultInfoWindow]="false"
    >
      <agm-marker
          *ngFor="let m of students; let i = index"
          (markerClick)="clickedMarker(m.name, i, infowindow)"
          [latitude]="m.latitude"
          [longitude]="m.longitude"
          [markerDraggable]="false"
          >

        <agm-info-window #infowindow>
          <div class="d-flex justify-content-center mb-3 mt-2"><img [src]="m.image" class="img-fluid rounded-circle shadow" width="80" height="80"></div>
            <h5 class="font-weight-bold text-center mb-1"><a href="javascript:void(0)" class="text-dark" (click)="showStudentDetail(m.idcard)">{{m?.name}}</a></h5>
            <p class="text-center">
              <a class="badge badge-danger" href="javascript:void(0)" (click)="goHomeStudent(m.latitude, m.longitude)" >
                ไปบ้านนักเรียน <i class="tim-icons icon-minimal-right ml-1"></i>
              </a>
            </p>
          <!-- <a href="javascript:void(0)" (click)="showStudentDetail(m.idcard)" ><strong>{{m?.name}}</strong></a><br>
          <a href=""><strong>ไปบ้านนักเรียน</strong></a> -->
        </agm-info-window>
      </agm-marker>
    </agm-map>
  </div>
  <div class="map-section">
    <div class="map-section--user">
      <app-card-user [name]="user?.name"></app-card-user>
      <btn-hidden
      [btnClass]="'mb-0 mt-2 mr-2'"
      [btnIcon]="'icon-zoom-split'"
      [tooltipText]="tooltipSearch"
      [hidden]="searchLoading"
      (click)="onChangeSearch()"
    ></btn-hidden>
      <!-- <btn-hidden
        [btnClass]="'mb-0 mt-2 mr-2'"
        [btnIcon]="'icon-single-copy-04'"
        [tooltipText]="tooltipStudent"
        [hidden]="studentLoading"
        (click)="onChangeProfile()"
      ></btn-hidden> -->
      <button
        (click)="onReset()"
        type="button"
        class="btn btn-round btn-danger btn-icon btn-lg mb-0 mt-2 mr-2"
        placement="top"
        tooltip="รีเซ็ตการค้นหา"
        [disabled]="!btnReset"
      >
        <i class="tim-icons icon-refresh-01"></i>
      </button>
    </div>
    <div class="map-section--profile" >
      <div *ngIf="proviceLoading">
        <app-card-detail
          [province]="filter.province"
          [amphoe]="filter.amphoe"
          [studentCount]="this.studentCount"
        >
        </app-card-detail>
      </div>
      <div *ngIf="studentLoading">
        <app-card-profile *ngFor="let student of student;"
        [image]="student.image"
        [name]="student.name"
        [nickName]="student.nickname"
        [sex]="student.sex"
        [studentType]="student.studentType"
        [ability]="student.ability"
        [idCard]="student.idcard"
        [birthday]="student.birthday"
        [fatherName]="student.fatherName"
        [motherName]="student.motherName"
        [address]="student.address"
        [district]="student.district"
        [amphoe]="student.amphoe"
        [province]="student.province"
        [zipcode]="student.zipcode"
      ></app-card-profile>
      </div>

    </div>
    <div class="map-section--top" *ngIf="!searchLoading">
      <app-search-map [loading]="searchFormLoading"></app-search-map>
    </div>
    <!-- <div class="map-section--bottom">
      <btn-hidden
        [btnClass]="'mb-2'"
        [btnIcon]="'icon-single-02'"
        [tooltipText]="tooltipStudent"
        [hidden]="studentLoading"
        (click)="onChangeProfile()"
      ></btn-hidden>
      <btn-hidden
        [btnIcon]="'icon-zoom-split'"
        [tooltipText]="tooltipSearch"
        [hidden]="searchLoading"
        (click)="onChangeSearch()"
      ></btn-hidden>
    </div> -->
  </div>
</div>

