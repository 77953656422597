<h3 class="font-weight-bold">ข้อมูลการรับการรักษา</h3>
<div class="card">
    <div class="card-body pb-0">
        <h4 class="font-weight-bold mb-4">ข้อมูลด้านการแพทย์</h4>
        <dl class="row">
            <dt class="col-sm-4">โรคประจำตัว</dt>
            <dd class="col-sm-8">ไม่มีโรคประจำตัว</dd>
            <dt class="col-sm-4">ประวัติการแพ้ยา</dt>
            <dd class="col-sm-8">ไม่มีประวัติการแพ้ยา</dd>
            <dt class="col-sm-4">โรคภูมิแพ้</dt>
            <dd class="col-sm-8">ไม่มีโรคภูมิแพ้</dd>
            <dt class="col-sm-4">ข้อจำกัดอื่นๆ</dt>
            <dd class="col-sm-8">ไม่มีข้อจำกัดอื่นๆ</dd>
            <dt class="col-sm-4">ผลการตรวจทางการแพทย์</dt>
            <dd class="col-sm-8">ไม่มีผลการตรวจทางการแพทย์</dd>
          </dl>
    </div>
</div>
<div class="card">
    <div class="card-body pb-0">
        <h4 class="font-weight-bold mb-4">การเข้ารับการรักษา</h4>
        <div class="row mx-2">
            <div class="card card-sub">
                <div class="card-body">
                    <h5 class="mb-2">วันที่เข้ารับการรักษา <span class="text-dark font-weight-bold">12 มีนาคม 2561</span></h5>
                    <h4>สถานที่ <span class="text-dark font-weight-bold">โรงพยาบาล</span></h4>
                    <p>ผู้รักษา <span class="font-weight-bold">หมอ</span></p>
                    <p>รายละเอียดการรักษา</p>
                    <p class="text-muted"><small>หมายเหตุ (ถ้ามี)</small></p>
                </div>
            </div>
            <div class="card card-sub">
                <div class="card-body">
                    <h5 class="mb-2">วันที่เข้ารับการรักษา <span class="text-dark font-weight-bold">12 มีนาคม 2561</span></h5>
                    <h4>สถานที่ <span class="text-dark font-weight-bold">โรงพยาบาล</span></h4>
                    <p>ผู้รักษา <span class="font-weight-bold">นายแพทย์</span></p>
                    <p>รายละเอียดการรักษา</p>
                    <p class="text-muted"><small>หมายเหตุ (ถ้ามี)</small></p>
                </div>
            </div>
        </div>
    </div>
</div>