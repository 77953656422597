  <div [@animate]="{value:'*',params:{opacity:'0', duration: '600ms'}}">
  <!-- search province and amphoe -->
  <div class="card card-custom">
    <div class="card-body">
      <h4 class="card-title text-center mb-0 mt-2">ค้นหาจากจังหวัด</h4>
      <p class="text-muted text-center mt-0 mb-4"><small>ค้นหาข้อมูลที่อยู่นักเรียนตามจังหวัดและอำเภอ</small></p>
      <div class="input-search">
        <div class="input-group" [ngClass]="{ 'input-disabled': !provinces }">
          <div class="input-group-prepend">
            <span
              class="input-group-text input-group-search"
              [ngClass]="{ 'input-disabled': !provinces }"
            >
              <i class="tim-icons icon-map-big"></i>
            </span>
          </div>
          <select
            name="province"
            class="form-control form-search"
            (ngModelChange)="onSelectProvince($event)"
            [disabled]="!provinces"
            [ngModel]="selectedProvince"
            required
          >
            <option ngValue="1">เลือกจังหวัด</option>
            <option
              *ngFor="let province of provinces"
              [ngValue]="province.province_name"
            >{{province.province_name}}</option>
          </select>
        </div>
        <div class="input-group" [ngClass]="{ 'input-disabled': amphoeLoading === false }">
          <div class="input-group-prepend">
            <span
            class="input-group-text input-group-search"
            [ngClass]="{ 'input-disabled': amphoeLoading === false }"
            >
            <i class="tim-icons icon-square-pin"></i>
          </span>
          </div>
          <select
            name="amphoe"
            class="form-control form-search"
            (ngModelChange)="onSelectAmphoe($event)"
            [disabled]="!amphoeLoading"
            [(ngModel)]="selectedAmphoe"
            required
          >
            <option ngValue="1">เลือกอำเภอ</option>
            <option *ngFor="let amphoe of amphoes" [ngValue]="amphoe.amphoe_name" >{{amphoe.amphoe_name}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <!-- <button (click)="selectedOption = 'อ่างทอง'">Select Option 4</button> -->
  <!-- search name and surname -->
  <ng-template #rt let-r="result" let-t="term">
    <div class="img-select d-none d-lg-inline">
      <img [src]="r.image" class="img-fluid rounded-circle mr-2" width="40" height="40" style="margin-top: -35px;">
    </div>
    <div class="descrition-select">
      <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
      <p class="text-muted mt-1">
        <i class="tim-icons icon-square-pin"></i>
        ตำบล {{r.district}} อำเภอ {{r.amphoe}} จังหวัด {{r.province}} {{r.zipcode}}
      </p>
    </div>
  </ng-template>
  <div class="card card-custom">
    <div class="card-body">
      <h4 class="card-title text-center mb-0 mt-2">ค้นหาจากชื่อนักเรียน</h4>
      <p class="text-muted text-center mt-0 mb-4"><small>ค้นหาข้อมูลที่อยู่นักเรียนตามชื่อและนามสกุล</small></p>
      <div class="input-search">
        <div class="input-group">
          <div class="input-group-prepend" [ngClass]="{loading: loading}">
            <span *ngIf="loading" class="input-group-text input-group-search"><div class="form-loading"><div></div><div></div></div></span>
            <span *ngIf="!loading" class="input-group-text input-group-search"><i class="tim-icons icon-zoom-split"></i></span>
          </div>
          <input
            type="text"
            class="form-control form-search"
            placeholder="กรอกชื่อนักเรียนที่ต้องการค้นหา"
            (selectItem)="selectedItem($event)"
            [(ngModel)]="model"
            [ngModelOptions]="{standalone: true}"
            [ngbTypeahead]="search"
            [resultTemplate]="rt"
            [inputFormatter]="formatter"
          />
        </div>
      </div>
    </div>
  </div>

</div>

