import { Injectable, Inject } from '@angular/core';
import { environment } from './../../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { Subject, BehaviorSubject } from 'rxjs';
// import { WINDOW, LOCAL_STORAGE } from '@nguniversal/express-engine';
import { Router } from '@angular/router';
// import { AlertService } from 'ngx-alerts';
const BACKEND_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private token: string;
  private authStatusListener = new Subject<boolean>();
  private isAuthenticated = false;
  private tokenTimer: any;
  private errorListener = new Subject<any>();
  private authData: any;
  private authDataUpdated = new BehaviorSubject('');
  private btnLoadingListener = new Subject<boolean>();
  private alertListener = new Subject<boolean>();

  constructor(
    // @Inject(WINDOW) private window: Window,
    // @Inject(LOCAL_STORAGE) private localStorage: any,
    private http: HttpClient,
    private router: Router,
    ) { }

  getErrorListener() {
    return this.errorListener.asObservable();
  }

  getBtnLoadingListener() {
    return this.btnLoadingListener.asObservable();
  }

  getAlertListener() {
    return this.alertListener.asObservable();
  }

  getToken() {
    return this.token;
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  // getAuthStatusListerner() {
  //   return this.authStatusListener.asObservable();
  // }

  register(name: string, email: string, password: string, password_confirmation: string, birthday: string, gender: string) {
    const data = { name: name, email: email, password: password, password_confirmation: password_confirmation, birthday: birthday, gender: gender};
    // console.log(data);
    this.http.post<{data: any}>(BACKEND_URL + '/auth/register' , data)
      .subscribe(response => {
        console.log(response);
        this.router.navigate(['/auth/login']);
        // this.alertService.success('Already registered');
      } , error => {
        console.log(error.error.error);
        this.errorListener.next(error.error.error);
      });
  }

  login(email: string, password: string) {
    const data = {email: email, password: password};
    this.http.post<{access_token: string , data: any, expiresIn: number}>(BACKEND_URL + '/auth/login' , data)
      .subscribe(response => {
        console.log(response.data);
        const token = response.access_token;
        this.token = token;
        if (token) {
          const expiresInDuration = response.expiresIn;
          this.setAuthTimer(expiresInDuration);
          this.isAuthenticated = true;
          this.authStatusListener.next(true);
          const now = new Date();
          const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
          // console.log(expirationDate);
          this.saveAuthData(token , expirationDate,  response.data);
          this.getAuthName();
          this.router.navigate(['/'])
          .then(() => {
            window.location.reload();
          });;
          // console.log(response);
        }
      } , error => {
        this.btnLoadingListener.next(false);
        this.alertListener.next(true);
        this.errorListener.next(error.error);
        // console.log(error.error);
      });
  }

  logout() {
    console.log('logout');
    clearTimeout(this.tokenTimer);
    // this.http.get<{access_token: string , expiresIn: number}>(BACKEND_URL + '/logout')
    //   .subscribe(response => {
    //     this.token = null;
    //     this.isAuthenticated = false;
    //     this.authStatusListener.next(false);
    //     clearTimeout(this.tokenTimer);
    //     this.clearAuthData();
    //     this.router.navigate(['/']);
    //     console.log(response);
    //   } , error => {
    //     this.token = null;
    //     this.isAuthenticated = false;
    //     this.authStatusListener.next(false);
    //     clearTimeout(this.tokenTimer);
    //     this.clearAuthData();
    //     this.router.navigate(['/']);
    //     console.log(error);
    //   });
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.isAuthenticated = true;
      // console.log(this.isAuthenticated);
      this.setAuthTimer(expiresIn / 1000);
      this.authStatusListener.next(true);
    }
  }

  private setAuthTimer(duration: number) {
    // console.log('Setting timer: ' + duration);
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }

  private saveAuthData(token: string , expirationDate: Date, data: any) {

    localStorage.setItem('token' , token);
    localStorage.setItem('expiration' , expirationDate.toISOString());
    localStorage.setItem('name' , data.name);
    localStorage.setItem('image' , data.image);
    localStorage.setItem('email' , data.email);
    localStorage.setItem('user_id' , data.id);
  }

  private clearAuthData() {
    localStorage.removeItem('token');
    localStorage.removeItem('expiration');
    localStorage.removeItem('name');
    localStorage.removeItem('image');
    localStorage.removeItem('email');
    localStorage.removeItem('user_id');
  }

  private getAuthData() {
    const token = localStorage.getItem('token');
    const expirationDate = localStorage.getItem('expiration');
    const name = localStorage.getItem('name');
    const image = localStorage.getItem('image');
    const email = localStorage.getItem('email');
    const user_id = localStorage.getItem('user_id');
    if (!token || !expirationDate) {
      return;
    } else {
      return {
        token: token,
        expirationDate: new Date(expirationDate),
        name: name,
        image: image,
        email: email,
        user_id: user_id
      };
    }
  }

  getAuthName() {
    const token = localStorage.getItem('token');
    const expirationDate = localStorage.getItem('expiration');
    const name = localStorage.getItem('name');
    const image = localStorage.getItem('image');
    const email = localStorage.getItem('email');
    const user_id = localStorage.getItem('user_id');
    if (!token || !expirationDate) {
        this.authDataUpdated.next('');
        return this.authData = {
          name: '',
          username: '',
          profile_picture: '',
          user_id: '',
        };
    } else {
      this.authData = {
        name: name,
        email: email,
        image: image,
        user_id: user_id,
      };
      this.authDataUpdated.next(this.authData);
      return this.authData;
    }
  }

  getAuthDataUpdateListener() {
    return this.authDataUpdated.asObservable();
  }

  showAlerts(message: string, status: string): void {
    if (status === 'error') {
      // this.alertService.danger(message);
    }
    if (status === 'success') {
      // this.alertService.success(message);
    }
  }
}
