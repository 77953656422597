import { Component, OnInit } from '@angular/core';
import { AuthService } from './pages/auth/auth.service';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'maps';
  // private loginStatus;
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {

  }

  ngOnInit() {
    // this.authService.autoAuthUser();
    // const isAuth = this.authService.getIsAuth();
    // if(isAuth) {
    //   this.router.navigate(['/home']);
    // }
  }


}
